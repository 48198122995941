import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import Img from 'gatsby-image'

const PostList = styled.section`
  position: relative;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(300px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 14px;
  -ms-grid-column-align: center;
  justify-items: center;
  padding: 0;
  @media (min-width: 800px) {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
`

const Post = styled.article`
  width: 100%;
  position: relative;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Header = styled.h2`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  opacity: 0;
  animation: ${fadeOut} 0.5s ease;
  color: #000;

  &:hover {
    opacity: 1;
    animation: ${fadeIn} 0.5s ease;
  }
`

export default ({ posts }) => (
  <PostList className="post-list">
    {posts.map(({ node }) => {
      const media = node.frontmatter.gallery
      const heroImage = media.find(item => !!item.childImageSharp)
      return (
        <Post key={node.fields.slug}>
          <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
            <Img fluid={heroImage.childImageSharp.fluid} />
            <Header>{node.frontmatter.title}</Header>
          </Link>
        </Post>
      )
    })}
  </PostList>
)
